import { Col, Divider, Layout, Row, Space, Typography } from "antd"
import CopyButton from "components/CopyButton"
import OpenButton from "components/OpenButton"
import MonthSelect from "components/MonthSelect"
import DataTable from "components/DataTable"

function App() {
  return (
    <Layout style={{ minHeight: "100vh", alignItems: "center", padding: 24 }}>
      <Layout.Content style={{ maxWidth: 1280 }}>
        <Row gutter={[0, 16]} justify="center">
          <Col flex="auto">
            <Space size={0} split={<Divider type="vertical" />}>
              <OpenButton />
              <MonthSelect />
            </Space>
          </Col>
          <Col>
            <CopyButton />
          </Col>
          <Col span={24}>
            <DataTable />
          </Col>
          <Col>
            <Typography.Text type="secondary">
              Deployed on Pages
            </Typography.Text>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  )
}

export default App
