import { useRequest } from "ahooks"

function useUsers() {
  const { data } = useRequest(() =>
    fetch("https://tao.zess.cn/app/user/list").then((response) =>
      response.json()
    )
  )
  return [data]
}

export default useUsers
