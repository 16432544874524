import { Select } from "antd"
import { useGlobalState } from "common/store"

function MonthSelect() {
  const [data] = useGlobalState("data")
  const [month, setMonth] = useGlobalState("month")
  return (
    <Select
      style={{ width: 150 }}
      placeholder="请选择月份"
      allowClear={true}
      value={month}
      onChange={(month) => setMonth(month)}
      options={data.reduce((list, row) => {
        const value = row["结算时间"].substring(0, 7)
        if (list.find((x) => x.value === value) === undefined) {
          const [y, m] = value.split("-")
          const label = `${y} 年 ${m} 月`
          list.push({ label, value })
        }
        return list
      }, [])}
    />
  )
}

export default MonthSelect
