import Num from "big.js"
import { useEffect } from "react"
import { Table } from "antd"
import { useGlobalState } from "common/store"
import useDataList from "hooks/useDataList"

function DataTable() {
  const [data] = useGlobalState("data")
  const [month] = useGlobalState("month")
  const [selected, setSelected] = useGlobalState("selected")
  const [dataList] = useDataList()

  useEffect(() => {
    setSelected([])
  }, [data, month])

  return (
    <Table
      dataSource={dataList}
      rowKey="pid"
      tableLayout="fixed"
      columns={[
        {
          title: "PID",
          dataIndex: "pid",
        },
        {
          title: "推广位名称",
          dataIndex: "name",
        },
        {
          title: "昵称",
          dataIndex: "nick",
        },
        {
          title: "交易金额",
          dataIndex: "paid",
          render: (paid) => paid.toFixed(2),
        },
        {
          title: "结算金额",
          dataIndex: "fee",
          render: (fee) => fee.toFixed(2),
        },
        {
          title: "返利比例",
          dataIndex: "rate",
          render: (rate) => rate * 100 + "%",
        },
        {
          title: "返利金额",
          dataIndex: "commission",
          render: (commission) => commission.toFixed(2),
        },
        {
          title: "备注",
          dataIndex: "mark",
        },
      ]}
      pagination={false}
      rowSelection={{
        type: "checkbox",
        columnWidth: 50,
        selectedRowKeys: selected.map((x) => x.pid),
        onChange: (_, rows) => setSelected(rows),
      }}
      summary={() => {
        if (dataList.length === 0) return <></>
        const sum = {}
        for (const key of ["paid", "fee", "commission"]) {
          sum[key] = new Num(0)
          for (const item of selected.length ? selected : dataList) {
            sum[key] = sum[key].plus(item[key])
          }
        }
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell colSpan={3}>合计</Table.Summary.Cell>
              <Table.Summary.Cell>{sum.paid.toFixed(2)}</Table.Summary.Cell>
              <Table.Summary.Cell>{sum.fee.toFixed(2)}</Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>
                {sum.commission.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
      }}
    />
  )
}

export default DataTable
