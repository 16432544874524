import { read, utils } from "xlsx"
import Num from "big.js"

export function parseCSV(text) {
  const wb = read(text, { type: "string", raw: true })
  const ws = wb.Sheets["Sheet1"]
  const rows = utils.sheet_to_json(ws)
  for (const row of rows) {
    for (const key in row) {
      row[key] = row[key].trim()
    }
  }
  return rows
}

export function sumByKey(list = [], key) {
  return list.reduce((sum, row) => sum.plus(row[key]), new Num(0))
}

