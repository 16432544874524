import { Button, Upload } from "antd"
import { FolderOpenOutlined } from "@ant-design/icons"
import { useGlobalState } from "common/store"
import { parseCSV } from "common/helper"

function OpenButton() {
  const [, setData] = useGlobalState("data")
  const [, setMonth] = useGlobalState("month")
  const [, setSelected] = useGlobalState("selected")
  return (
    <Upload
      accept=".csv"
      multiple={false}
      showUploadList={false}
      beforeUpload={(file) => {
        const reader = new FileReader()
        reader.readAsText(file, "utf8")
        reader.onload = (e) => {
          const csv = parseCSV(e.target.result)
          setData(csv)
          setMonth(undefined)
          setSelected([])
        }
        return false
      }}
    >
      <Button icon={<FolderOpenOutlined />} />
    </Upload>
  )
}

export default OpenButton
