import copy from "copy-to-clipboard"
import { Button } from "antd"
import { CopyOutlined } from "@ant-design/icons"
import { useGlobalState } from "common/store"

function CopyButton() {
  const [selected] = useGlobalState("selected")
  return (
    <Button
      icon={<CopyOutlined />}
      onClick={() => {
        if (selected.length === 0) return
        const text = selected
          .map((x) => `${x.nick} ${x.commission.toFixed(2)}`)
          .join("\n")
        copy(text, { format: "text/plain" })
      }}
    >
      复制
    </Button>
  )
}

export default CopyButton
