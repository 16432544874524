import { useMemo } from "react"
import { useGlobalState } from "common/store"
import { sumByKey } from "common/helper"
import useUsers from "hooks/useUsers"

function useDataList() {
  const [users] = useUsers()
  const [data] = useGlobalState("data")
  const [month] = useGlobalState("month")

  const list = useMemo(() => {
    const list = data
      .filter((row) => (month ? row["结算时间"].startsWith(month) : true))
      .reduce((list, row) => {
        const pid = row["推广位ID"]
        const item = list.find((x) => x.pid === pid)
        if (item) item.orders.push(row)
        else {
          const user = users?.find((x) => x.pid === pid)
          list.push({
            pid: pid,
            orders: [row],
            name: row["推广位名称"],
            rate: user ? user.rate : 0,
            nick: user ? user.name : "",
            mark: user ? user.mark : "",
          })
        }
        return list
      }, [])
    return list
      .sort((a, b) => a.pid - b.pid)
      .map((row) => {
        const paid = sumByKey(row.orders, "付款金额")
        const share = sumByKey(row.orders, "平台技术服务费")
        const fee = sumByKey(row.orders, "结算预估收入").minus(share)
        const commission = fee.times(row.rate)
        return { ...row, paid, fee, commission }
      })
  }, [data, month, users])

  return [list]
}

export default useDataList
